import { useFrame, useThree } from "@react-three/fiber"

const CameraLightController = () =>{
    const { scene } = useThree()
    useFrame(({camera}) => {
        const directionalLight = scene.getObjectByName('light')
        if(directionalLight){
            directionalLight.position.copy(camera.position)
        }
    })
    return(<></>)
}

export default CameraLightController
