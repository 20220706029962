import * as THREE from 'three'

function createBufferGeometryFromCtmFile ( file ) {

    const geometry = new THREE.BufferGeometry();
    const vertices = file.body.vertices
	const indices = file.body.indices
	
	geometry.setIndex( new THREE.BufferAttribute( indices, 1 ))
    geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) )
	// normals not presented
	geometry.computeVertexNormals()

    return ( geometry )

};

export default createBufferGeometryFromCtmFile
