import { ResizeObserver } from "@juggle/resize-observer"
import { Canvas } from "@react-three/fiber"
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useRef } from "react"
import * as THREE from 'three'
import { TApplicationActiveTab, TModelStateAccumulator, TViewResetFlag } from "../JsTpviewer"
import CameraLightController from "../Scene/CameraLightController"
import { TViewValue } from "../types"
import SwitchBeforeAfter from "./SwitchBeforeAfter"
import ViewControls from "./ViewControls"
import isTouch from "../Utils/isTouch"
import ModelViewController from "../Scene/ModelViewController"
import CameraZoomController from "../Scene/CameraZoomController"
import Information from "./Information"



type TView3dBeforeAfterProps = {
    setCameraParameters      : (x: number, y: number, z: number, zoom: number) => void
    cameraPosition           : MutableRefObject<{
        position: THREE.Vector3
        zoom: number
    }>
    teethsModelsBefore  : JSX.Element | undefined                
    teethsModelsAfter   : JSX.Element | undefined    
    gingivaModelsBefore : JSX.Element | undefined
    gingivaModelsAfter  : JSX.Element | undefined    

    activeTab : TApplicationActiveTab
    setActiveTab: Dispatch<SetStateAction<TApplicationActiveTab>>
    currentView     : TViewValue
    setCurrentView  : Dispatch<SetStateAction<TViewValue>>
    isViewClicked   : boolean
    setViewClicked  : Dispatch<SetStateAction<boolean>>
    setStepIndex    : Dispatch<SetStateAction<number>>
    modelStateAccumulatorRef: React.MutableRefObject<TModelStateAccumulator>
    prevTabRef: React.MutableRefObject<TApplicationActiveTab>
    isNeedToResetViewRef: React.MutableRefObject<TViewResetFlag>
    
    isViewMirrored : boolean
    setViewMirrored: Dispatch<SetStateAction<boolean>>

    smilewrapperInfo: string | undefined
}

export type TVec2 = {
    x:number 
    y:number
}

const View3dBeforeAfter = (props: TView3dBeforeAfterProps) =>{

    const refBeforeAfter = useRef<any>(null)
    const beforeRef      = useRef<any>(null)
    const afterRef       = useRef<any>(null)

    
    const {
        setCameraParameters,

        teethsModelsBefore,
        teethsModelsAfter,
        gingivaModelsBefore,
        gingivaModelsAfter,

        activeTab,
        currentView,
        isViewClicked,
        setActiveTab,
        setCurrentView,
        setViewClicked,
        setStepIndex,
        modelStateAccumulatorRef,
        isNeedToResetViewRef,
        isViewMirrored,
        setViewMirrored,
        smilewrapperInfo
    } = props

    const isMouseDown      = useRef<boolean>(false)
    const isMouseRightDown = useRef<boolean>(false)
    
    const mouseXYDefault:TVec2 = {x:-1, y:-1}
    const mouseXYStart   = useRef(mouseXYDefault)
    const touchesXYStart = useRef([mouseXYDefault,mouseXYDefault])    
    const mouseXYDelta   = useRef({x:0, y:0})
    const zoomWheelValue = useRef(0)
    const touchesDistanceStart = useRef(0)
    

    const controlsMouseDown = useCallback((e:any)=>{
        
        e.preventDefault()
        isMouseDown.current = true

        if(isTouch(e)){
            if(typeof(e.touches)!=='undefined' && e.touches.length === 1){
                mouseXYStart.current = {x: e.touches[0].clientX, y: e.touches[0].clientY }
            }else if(typeof(e.touches)!=='undefined' && e.touches.length === 2){
                isMouseRightDown.current = true
                mouseXYStart.current = {
                    x: (e.touches[0].clientX + e.touches[1].clientX)/2, 
                    y: (e.touches[0].clientY + e.touches[1].clientY)/2 
                }

                touchesXYStart.current = [
                    {x: e.touches[0].clientX, y: e.touches[0].clientY },
                    {x: e.touches[1].clientX, y: e.touches[1].clientY }
                ]

                touchesDistanceStart.current = Math.sqrt(Math.pow(e.touches[0].clientX - e.touches[1].clientX, 2) + Math.pow(e.touches[0].clientY - e.touches[1].clientY,2))

            }else if(typeof(e.clientX)!=='undefined' && typeof(e.clientY)!=='undefined' ){
                mouseXYStart.current = {x: e.clientX, y: e.clientY }
            }
        }else{
            if(e.button === 2){ // RIght Mouse Button Click
                isMouseRightDown.current = true
            }
            mouseXYStart.current = {x: e.clientX, y: e.clientY }
        }
        
    },[])

    const controlsMouseUp = useCallback((e:any)=>{
        
        isMouseDown.current      = false
        isMouseRightDown.current = false

        mouseXYStart.current = mouseXYDefault
        mouseXYDelta.current = {x:0, y:0}
        touchesXYStart.current = [mouseXYDefault,mouseXYDefault]
        zoomWheelValue.current = 0
        touchesDistanceStart.current = 0
    },[])

    const controlsMouseMove = useCallback((e:any)=>{
        
        if(isMouseDown.current === true){
            if(isTouch(e) === false){
                let deltaXY:TVec2 = {
                    x: mouseXYStart.current.x - e.clientX ,
                    y: mouseXYStart.current.y - e.clientY ,
                }
                mouseXYDelta.current = deltaXY    
            }
            if(isTouch(e) === true){
                // ROTATE VIEW
                if(typeof(e.touches)!=='undefined' && e.touches.length === 1){
                    let deltaXY:TVec2 =
                    {
                        x: mouseXYStart.current.x - e.touches[0].clientX,
                        y: mouseXYStart.current.y - e.touches[0].clientY
                    }
                    mouseXYDelta.current = deltaXY
                } else if(typeof(e.touches)!=='undefined' && e.touches.length === 2){
                    // MOVE MOBILE VIEW
                    let deltaXY:TVec2 =
                    {
                        x: mouseXYStart.current.x - (e.touches[0].clientX+e.touches[1].clientX)/2,
                        y: mouseXYStart.current.y - (e.touches[0].clientY+e.touches[1].clientY)/2
                    }
                    mouseXYDelta.current = deltaXY

                    // ZOOM MOBILE VIEW
                    const touchesDistanceCurrent   = Math.sqrt(Math.pow(e.touches[0].clientX - e.touches[1].clientX, 2) + Math.pow(e.touches[0].clientY - e.touches[1].clientY,2))
                    const touchesDistanceDifference = touchesDistanceCurrent - touchesDistanceStart.current

                    const zoomTouchesDistanceTriggerValue = 80 // px

                    if( touchesDistanceDifference > zoomTouchesDistanceTriggerValue ){
                        zoomWheelValue.current = 1
                        setTimeout(()=>{ zoomWheelValue.current = 0 },25)
                    } else if( touchesDistanceDifference < -zoomTouchesDistanceTriggerValue ){
                        zoomWheelValue.current = -1
                        setTimeout(()=>{ zoomWheelValue.current = 0 },25)
                    } else{
                        zoomWheelValue.current = 0
                    }
                }
            }
        }else{
            mouseXYDelta.current = {x:0, y:0}
        }
    },[])

    const controlsZoom = useCallback((e:any)=>{
        if(e.deltaY > 0){ zoomWheelValue.current = 1 }
        if(e.deltaY < 0){ zoomWheelValue.current = -1 }
        setTimeout(()=>{zoomWheelValue.current = 0},20)
    },[])

    const contextMenu = (e:any) =>{
        e.preventDefault() // disable rightClick menu
    }

    useEffect(()=>{
        
        if(refBeforeAfter.current){

            refBeforeAfter.current.addEventListener( 'mousemove'  , controlsMouseMove )
            refBeforeAfter.current.addEventListener( 'mousedown'  , controlsMouseDown )
            refBeforeAfter.current.addEventListener( 'mouseup'    , controlsMouseUp   )
            refBeforeAfter.current.addEventListener( 'mouseout'   , controlsMouseUp   )
            refBeforeAfter.current.addEventListener( 'contextmenu'   , contextMenu   )
            refBeforeAfter.current.addEventListener( 'wheel'      , controlsZoom      )

            refBeforeAfter.current.addEventListener( 'touchmove'  , controlsMouseMove )
            refBeforeAfter.current.addEventListener( 'touchstart' , controlsMouseDown )
            refBeforeAfter.current.addEventListener( 'touchend'   , controlsMouseUp   )
            refBeforeAfter.current.addEventListener( 'touchcancel', controlsMouseUp   )

        }
        return(()=>{
            // refBeforeAfter.current.removeEventListener( 'mousemove', controlsMouseMove )
            // refBeforeAfter.current.removeEventListener( 'mousedown', controlsMouseDown )
            // refBeforeAfter.current.removeEventListener( 'mouseup'  , controlsMouseUp   )
            // refBeforeAfter.current.removeEventListener( 'mouseout' , controlsMouseUp   )
        })
    },[])


    return(
        <>  
            <div id='view-before-after'
                
            >
                <div className='view-controls-panel' 
                    ref={ refBeforeAfter }
                    onMouseOut = { controlsMouseUp }  
                />

                <div className="canvas-wrapper"
                    ref = { beforeRef }
                >
                    <div className='title' id='title_before'>
                        Before
                    </div>
                    <Canvas
                        resize={{ 
                            polyfill: ResizeObserver,
                            scroll: true, 
                            debounce: { 
                                scroll: 50, 
                                resize: 0 
                            }
                        }}
                        
                        gl={{ 
                            antialias: true,
                            autoClearColor: true,
                            toneMapping: THREE.NoToneMapping,
                        }}
                        
                        style={{
                            transform: isViewMirrored ? 'scaleX(-1)' : undefined
                        }}

                        legacy
                        orthographic
                    > 
                        <ambientLight color={'#ffffff'} intensity={-0.2}/>
                        <directionalLight name='light'  color= { 0xffffff } intensity={ 2.0 } position={[0,0,10]} />
                        
                        <CameraLightController/>

                        <hemisphereLight 
                            //args={['#888899', '#333344']} // skyColor={ 0x443333 } groundColor={ 0x111122 } intensity
                            args={['#161111', '#161111']}
                            intensity={1}
                        />

                        { teethsModelsBefore  }

                        { gingivaModelsBefore }

                        <ModelViewController
                            viewType                 = "before"
                            activeTab                = { activeTab                  }
                            isMouseDown              = { isMouseDown                }
                            isMouseRightDown         = { isMouseRightDown           }
                            delta                    = { mouseXYDelta               }
                            currentView              = { currentView                }
                            isNeedToResetViewRef     = { isNeedToResetViewRef       }
                            modelStateAccumulatorRef = { modelStateAccumulatorRef   }
                            isViewMirrored           = { isViewMirrored             }
                        />
                        
                        <CameraZoomController
                            viewType       = "before"
                            activeTab      = { activeTab      } 
                            currentView    = { currentView    }
                            zoomWheelValue = { zoomWheelValue }
                            isNeedToResetViewRef     = { isNeedToResetViewRef       }
                        />
                    </Canvas>
                    <Information 
                        smilewrapperInfo = { smilewrapperInfo }
                    />
                </div>

                <div className='label-anchor'>
                    <img id='label-3d' src="3d.png" alt="3d"/>
                </div>
                
                <div className="canvas-wrapper"
                    ref = {afterRef}
                >
                    <div className='title' id='title_after'>
                        After
                    </div>
                    <Canvas
                        resize={{ 
                            polyfill: ResizeObserver,
                            scroll: true, 
                            debounce: { 
                                scroll: 50, 
                                resize: 0 
                            }
                        }} 
                        gl={{ 
                            antialias: true,
                            autoClearColor: true,
                            toneMapping: THREE.NoToneMapping,
                        }}

                        style={{
                            transform: isViewMirrored ? 'scaleX(-1)' : undefined
                        }}

                        legacy
                        orthographic
                    > 
                        <ambientLight color={'#ffffff'} intensity={-0.2}/>
                        <directionalLight name='light'  color= { 0xffffff } intensity={ 2.0 } position={[0,0,100]} />
                        
                        <CameraLightController/>

                        <hemisphereLight 
                            //args={['#888899', '#333344']} // skyColor={ 0x443333 } groundColor={ 0x111122 } intensity
                            args={['#161111', '#161111']}
                            intensity={1}
                        />

                        { teethsModelsAfter  }

                        { gingivaModelsAfter }

                        <ModelViewController
                            viewType                 = "after"
                            activeTab                = { activeTab                  }
                            isMouseDown              = { isMouseDown                }
                            isMouseRightDown         = { isMouseRightDown           }
                            delta                    = { mouseXYDelta               }
                            currentView              = { currentView                }
                            isNeedToResetViewRef     = { isNeedToResetViewRef       }
                            modelStateAccumulatorRef = { modelStateAccumulatorRef   }
                            isViewMirrored           = { isViewMirrored             }
                        />

                        <CameraZoomController
                            viewType       = "after"
                            activeTab      = { activeTab      } 
                            currentView    = { currentView    }
                            zoomWheelValue = { zoomWheelValue }
                            isNeedToResetViewRef     = { isNeedToResetViewRef       }
                        />

                    </Canvas>
                    <Information 
                        smilewrapperInfo = { smilewrapperInfo }
                        className="information_after"
                    />
                </div>

            </div>
            <div id='controls'>

                <ViewControls 
                    currentView          = { currentView            }
                    setCurrentView       = { setCurrentView         }
                    setViewClicked       = { setViewClicked         }
                    isNeedToResetViewRef = { isNeedToResetViewRef   }
                    viewControl          = 'before|after'
                />

                <SwitchBeforeAfter 
                    activeTab       = { activeTab       }
                    setActiveTab    = { setActiveTab    }
                    setStepIndex    = { setStepIndex    }
                    isViewMirrored  = { isViewMirrored  }
                    setViewMirrored = { setViewMirrored }
                />
            </div>
        </>
    )
}

export default View3dBeforeAfter
