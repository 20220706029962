import JSZip from "jszip"
import createBubberGeomeryFromCtmFile from '../Utils/create-buffer-geometry-from-ctm-file'
import CTM from './ctm/ctm'
import * as THREE from 'three'
import { TSmilewrapperModelDataHandlers } from "./getSmilewrapperModelsData"
import { TTeethStepsPosition } from "./ModelLoader"
import {TModelData, TSmaileWrapper, TSteppedModelData, TTeethModelData} from "../types"

const unzipSmilewrapper = (openedArchive:JSZip, handlers: TSmilewrapperModelDataHandlers) =>{

    const {
        //url,
        setTeethModelData,
        setGingivaModelData,
        setTeethModelStepTransformation,
        setSmilewrapperInfo,
        onGlobalError,
        onFinish
    } = handlers

    const files = Object.entries(openedArchive.files).map(item => item[1])
    
    let teethStepsPositions:TTeethStepsPosition[][] = []
    let smileWrapperInfo: string | undefined
    const teethModelData:TTeethModelData = {
        upper: [],
        lower: []
    }

    const gingivasModelData:TSteppedModelData = {
        upperSteps: [],
        lowerSteps: []
    }

    const smilewrapperInfo = files.filter((fileItem, index) => {
        return( fileItem.name === 'smilewrapper.json' )
    }).map((filteredFileItem, index)=>{
        return(
            filteredFileItem.async('string').then( function(unzippedData){
                try{
                    
                    const smilewrapper = JSON.parse(unzippedData) as TSmaileWrapper

                    const { additionalInfo } = smilewrapper
                    if(additionalInfo && additionalInfo.guid ) {
                        smileWrapperInfo = additionalInfo.guid
                    }else if(additionalInfo && additionalInfo.ID){
                        smileWrapperInfo = additionalInfo.ID
                    }

                    return( smileWrapperInfo )
                } catch (e) {
                    console.log('Cant parse smilewrapper.json and get info', JSON.stringify(e))
                }
            }
        ))
    })
    

    const smilewrapperArray = files.filter((fileItem, index) => {
        return( fileItem.name === 'smilewrapper.json' )
    }).map((filteredFileItem, index)=>{
        return(
            filteredFileItem.async('string').then( function(unzippedData){
                try{
                    
                    const smilewrapper = JSON.parse(unzippedData) as TSmaileWrapper 
                    const { transformations } = smilewrapper


                    teethStepsPositions = transformations.map((stepItem) => {
                        // OLD SMILE-Wrapper format
                        let stepTransformations = Object.entries(stepItem)
                        .filter(item=> !isNaN( Number(item[0])))
                        .map((transformationItem) =>{

                            const rotationMatrix = new THREE.Matrix3()
                            rotationMatrix.elements = transformationItem[1][0].flat()
                            return({
                                id: transformationItem[0],
                                position: new THREE.Vector3(
                                    transformationItem[1][1][0],
                                    transformationItem[1][1][1],
                                    transformationItem[1][1][2]
                                ),
                                rotationMatrix
                            })
                        })
                        // TRY ADVANCED FORMAT
                        if(stepTransformations.length === 0 && stepItem.tooth_id){
                            stepTransformations = Object.entries(stepItem.tooth_id)
                            .filter(item=> !isNaN( Number(item[0])))
                            .map((transformationItem) =>{

                                const rotationMatrix = new THREE.Matrix3()
                                rotationMatrix.elements = transformationItem[1][0].flat()
                                return({
                                    id: transformationItem[0],
                                    position: new THREE.Vector3(
                                        transformationItem[1][1][0],
                                        transformationItem[1][1][1],
                                        transformationItem[1][1][2]
                                    ),
                                    rotationMatrix
                                })
                            })
                        }
                        return (stepTransformations)
                    })

                } catch (e) {
                    console.log('Cant parse smilewrapper.json', JSON.stringify(e))
                }
            })
        )
    })

    // UNZIP TEETH 3D-OBJECTS
    const teethUpperGeometry = files.filter((fileItem, index)=>{
        return(
            fileItem.name.indexOf('.ctm')>-1 && 
            fileItem.name.indexOf('teeth')>-1 &&
            (
                fileItem.name.indexOf('_1')>-1 ||
                fileItem.name.indexOf('_2')>-1
            )
        )
    }) 
    .map((filteredFileItem, index)=>{
        return(
            filteredFileItem.async('uint8array').then( function(unzippedData){
                const ctmFile = new CTM.File( new CTM.Stream(unzippedData) )                            
                teethModelData.upper.push({
                    name: filteredFileItem.name,
                    data:  createBubberGeomeryFromCtmFile(ctmFile)
                })
            })
        )
    })

    // UNZIP TEETH 3D-OBJECTS
    const teethLowerGeometry = files.filter((fileItem, index)=>{
        return(
            fileItem.name.indexOf('.ctm')>-1 && 
            fileItem.name.indexOf('teeth')>-1 &&
            (
                fileItem.name.indexOf('_3')>-1 ||
                fileItem.name.indexOf('_4')>-1
            )
        )
    }) 
    .map((filteredFileItem, index)=>{
        return(
            filteredFileItem.async('uint8array').then( function(unzippedData){
                const ctmFile = new CTM.File( new CTM.Stream(unzippedData) )                            
                teethModelData.lower.push({
                    name: filteredFileItem.name,
                    data:  createBubberGeomeryFromCtmFile(ctmFile)
                })
            })
        )
    })

    // UNZIP GINGIVA 3D-OBJECTS
    const gingivaUpperGeometry = files.filter((fileItem, index)=>{
        return(
            fileItem.name.indexOf('.ctm')>-1 && 
            fileItem.name.indexOf('gingivas')>-1 &&
            fileItem.name.indexOf('upper')>-1
        )
    }) 
    .map((filteredFileItem, index)=>{
        return(
            filteredFileItem.async('uint8array').then( function(unzippedData){
                const ctmFile = new CTM.File( new CTM.Stream(unzippedData) )                            
                gingivasModelData.upperSteps.push({
                    name: filteredFileItem.name,
                    data:  createBubberGeomeryFromCtmFile(ctmFile)
                })
            })
        )
    })

    // UNZIP GINGIVA 3D-OBJECTS
    const gingivaLowerGeometry = files.filter((fileItem, index)=>{
        return(
            fileItem.name.indexOf('.ctm')>-1 && 
            fileItem.name.indexOf('gingivas')>-1 &&
            fileItem.name.indexOf('lower')>-1
        )
    }) 
    .map((filteredFileItem, index)=>{
        return(
            filteredFileItem.async('uint8array').then( function(unzippedData){
                const ctmFile = new CTM.File( new CTM.Stream(unzippedData) )                            
                gingivasModelData.lowerSteps.push({
                    name: filteredFileItem.name,
                    data:  createBubberGeomeryFromCtmFile(ctmFile)
                })
            })
        )
    })

    // START UNZIPPING ALL  
    Promise.all([
        ...teethUpperGeometry,
        ...teethLowerGeometry,
        ...gingivaUpperGeometry,
        ...gingivaLowerGeometry,
        smilewrapperArray,
        smilewrapperInfo,
    ])
    .then(()=>{
        const comp = (a: TModelData, b: TModelData) => Number(a.name.slice(-15) > b.name.slice(-15)) * 2 - 1;

        gingivasModelData.lowerSteps.sort(comp)
        gingivasModelData.upperSteps.sort(comp)

        setTeethModelData(teethModelData)
        setGingivaModelData(gingivasModelData)
        setTeethModelStepTransformation(teethStepsPositions)
        setSmilewrapperInfo(smileWrapperInfo)
        
        if(
            (
                gingivasModelData.lowerSteps.length === 0 ||
                gingivasModelData.upperSteps.length === 0 
            ) ||
            (
                teethModelData.lower.length === 0 ||
                teethModelData.upper.length === 0
            )
        ){
            onGlobalError('ZIP-file content is empty')
        }
        if( onFinish ){
            onFinish()
        }

    }).catch((error)=>{
        onGlobalError('CANT PARSE UNZIPPED DATA FROM CTM FILE')
        console.log('CANT PARSE UNZIPPED DATA FROM CTM FILE', JSON.stringify(error))
    })
}

export default unzipSmilewrapper
