const GlobalError = () =>{
    return(
        <div id='global-error'>
            <h2>
                Error: unfortunately,<br/>
                something went a bit off.
            </h2>
            <div id='error-message-text'>
                We're having trouble generating your Custom <br/> Smile Plan. Please try again in a few minutes
            </div>
        </div>
    )
}

export default GlobalError
