const MirrorIcon = () =>{
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect fill="black" x="11" y="2" width="2" height="3"/>
            <rect fill="black" x="11" y="6.5" width="2" height="3"/>
            <rect fill="black" x="11" y="11" width="2" height="3"/>
            <rect fill="black" x="11" y="15.5" width="2" height="3"/>
            <rect fill="black" x="11" y="20" width="2" height="3"/>
            <path fill="black" d="M1,3v18l9-4.3V7.3L1,3z M8,15.6L2,19V5l6,3.4V15.6z"/>
            <path fill="black" d="M14,7.3v9.4l9,4.3V3L14,7.3z M22,19l-6-3.4V8.4L22,5V19z"/>
        </svg>
    )
}
export default MirrorIcon
