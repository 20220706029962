
const Preloader = () => {
    return(
        <div className='backgound-wrapper'>

            <div className="loader-wrapper">
                <div className="loader">
                    <svg className="circular-loader"viewBox="25 25 50 50" >
                        <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#5700ff" strokeWidth="5" />
                    </svg>
                </div>
                <div className="loader-text">
                    Behold, your new smile is about to stare at you
                </div>
            </div>
        </div>
    )
}

export default Preloader
