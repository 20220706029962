import * as THREE from 'three'
import { TModelData, TViewValue } from "../../types"
import { getBaseRotation } from '../ModelViewController'

type TTeethsProps = {
    modelName   : string
    modelData   : TModelData[],
    currentView : TViewValue
    opacity?    : boolean
}


export const toothMaterial = new THREE.MeshPhongMaterial({
    color: 0xffffff,
    shininess: 100,
    specular: 0x666666,
    
    flatShading: false,
    emissive: 0x050505,
})

export const getViewVisibility = (currentView: TViewValue, modelName: string) => {

    switch(currentView){
        
        case "top":
            return(modelName.indexOf('-lower')>-1 ? false : true)
        case "bottom":
            return(modelName.indexOf('-upper')>-1 ? false : true)
        default:
            return true
    }
}

const Teeths = ( props:TTeethsProps ) => {
    
    const {
        modelData, 
        modelName,
        currentView,
    } = props


    return(
        <group
            name     = { modelName                                }
            rotation = { getBaseRotation(currentView)             }
            visible  = { getViewVisibility(currentView,modelName) }
        >
            {
                modelData.map((modelItem, modelIndex)=>{

                    const { name, data } = modelItem
                    let   newModelName        = name
                    const splittedName = name.split('_')

                    if(splittedName.length === 3){
                        const splittedFileName = splittedName[2].split('.')
                        newModelName = `teeth-${splittedFileName[0]}`
                    }

                    return(
                        <mesh
                            name     = { newModelName                           }
                            key      = {`${modelName}-teeth-item-${modelIndex}` }
                            geometry = { data                                   }
                            material = { toothMaterial }
                        />
                    )
                })
            }

        </group>
    )
}

export default Teeths
