// @ts-ignore
import memoryParams from 'tpviewer-wasm/memory_params'
// @ts-ignore
import iosDetect from 'tpviewer-wasm/ios_detect'
// @ts-ignore
import ioFiles from 'tpviewer-wasm/io_files'
// @ts-ignore
import resize from 'tpviewer-wasm/resize'
// @ts-ignore
import popups from 'tpviewer-wasm/popups'
// @ts-ignore
import resetEvents from 'tpviewer-wasm/reset_events'
// @ts-ignore
import scrollableArea from 'tpviewer-wasm/scrollable_area'
import 'tpviewer-wasm/styles.css'
// @ts-ignore
import tpViewerData from 'tpviewer-wasm/tpviewer.data'
// @ts-ignore
import tpViewerWasmBinary from 'tpviewer-wasm/tpviewer.wasm'
// @ts-ignore
import tpviewerWasm from 'tpviewer-wasm/tpviewer'
import React, {useCallback, useEffect, useRef, useState} from "react"
import {Helmet, HelmetTags} from "react-helmet"
import Preloader from "./Ui/Preloader"

const TP_VIEWER_FILES_MAPPING: { [key: string]: string } = {
    'tpviewer.data': tpViewerData as string,
    'tpviewer.wasm': tpViewerWasmBinary as string
}

type WasmTpviewerProps = {
    onCrash: () => void
}

const WasmTpviewer = (props: WasmTpviewerProps) => {
    const [memoryParamsScriptLoaded, setMemoryParamsScriptLoaded] = useState(false)
    const handleScriptInject = (newState:any, addedTag:HelmetTags, removedTag:HelmetTags) => {
        if (addedTag.scriptTags && !memoryParamsScriptLoaded) {
            // Using of [0] is important. 0-index means memoryParams script
            addedTag.scriptTags[0].onload = () => {
                // Call initMemoryParams to init global variables like param_... which used by tpviewerWasm
                (window as any).initMemoryParams();
                // tpviewerWasm script can be loaded only if memoryParams loaded
                setMemoryParamsScriptLoaded(true);
            }
        }
    }

    const { onCrash } = props

    const canvasRef = useRef<HTMLCanvasElement>(null) 
    let [showCanvas, setShowCanvas] = useState(false)

    const webglContextHandler = useCallback((e:Event)=>{
        alert("WebGL context lost. You will need to reload the page.");
        e.preventDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {

        canvasRef.current!.addEventListener("webglcontextlost", webglContextHandler, false)
        
        window.onerror = e => {
            console.error(e);
            onCrash();
        }

        (window as any).Module = {
            locateFile: function (path: string, _prefix: string): string {
                return TP_VIEWER_FILES_MAPPING[path] || path;
            },
            preRun: [],
            postRun: () => {
                setShowCanvas(true);
                (window as any).registerResize();
                (window as any).updatePreventFuncAndCalculateMouseEvent();
            },
            onAbort: (reason: string) => {
                console.error(`Failed to initialize tpviewer-wasm due to error: ${reason}`);
                onCrash();
            },
            canvas: canvasRef.current,
            totalDependencies: 0,
            monitorRunDependencies: function (n: number) {
                this.totalDependencies = Math.max(this.totalDependencies, n);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onCrash])

    return (
        <>
            { !showCanvas &&  <Preloader /> }
            <div>
                <Helmet onChangeClientState={handleScriptInject}>
                    {/* Order of injected script tags is important. See handleScriptInject */}
                    <script type="text/javascript" src={memoryParams}></script>
                    <script type="text/javascript" src={iosDetect}></script>
                    <script type="text/javascript" src={popups}></script>
                    <script type="text/javascript" src={ioFiles}></script>
                    <script type="text/javascript" src={resetEvents}></script>
                    <script type="text/javascript" src={scrollableArea}></script>
                    <script type="text/javascript" src={resize}></script>
                    {memoryParamsScriptLoaded && <script type="text/javascript" src={tpviewerWasm}></script>}
                </Helmet>
                <canvas
                    style={{
                        display: showCanvas ? "block" : "none"
                    }}
                    className="emscripten"
                    id="canvas"
                    ref={canvasRef}
                    tabIndex={-1}
                ></canvas>
            </div>
        </>
    )
}

export default WasmTpviewer
