import { TModelData } from "../types"
import { mergeBufferGeometries } from "./BufferGeometryUtils"
import { TTeethStepsPosition } from "./ModelLoader"
import * as THREE from 'three'


const getCtmTransformedGeometry = ( transformation: TTeethStepsPosition[], geometryData: TModelData[] ) => {
    const transformedGeometry:THREE.BufferGeometry[] = []

    transformation.forEach( (transformationItem) =>{
                
        const filteredModel = geometryData
            .filter( dataItem => dataItem.name.slice(-6).indexOf( transformationItem.id ) > -1)

        if(filteredModel.length === 1){
            const currentModel = filteredModel[0]
            const modelGeometryData =  currentModel.data.clone()
            const rotationMatrix =  transformationItem.rotationMatrix.clone()
            

            const tMatrix4 = new THREE.Matrix4()
            const rmtx = rotationMatrix.transpose().toArray()
            const tVec = transformationItem.position
            tMatrix4.elements = [
                rmtx[0], rmtx[1], rmtx[2], 0,
                rmtx[3], rmtx[4], rmtx[5], 0,
                rmtx[6], rmtx[7], rmtx[8], 0,
                tVec.x , tVec.y , tVec.z , 1
            ]
            modelGeometryData.applyMatrix4(tMatrix4)
            transformedGeometry.push(modelGeometryData)
        }                    
    })


    return ( mergeBufferGeometries(transformedGeometry))
}

export default getCtmTransformedGeometry
