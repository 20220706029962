import { State, SCXML,  createMachine, SingleOrArray, Event } from "xstate"
import { useMachine } from "@xstate/react"
import { useCallback } from "react"

export type TMachineStateEvent = 
| { type: 'event_loading_finish'                }
| { type: 'event_pause'                         }
| { type: 'event_stop'                          }
| { type: 'event_change_view'                   }
| { type: 'event_play_view'                     }
| { type: 'event_loading_from_url'              }
| { type: 'event_loading_from_local_file'       }
| { type: 'event_loading_finished_no_animation' }

const veiwerStateMachine = createMachine({
/** @xstate-layout N4IgpgJg5mDOIC5QEMAOqDKAXZWwFlkBjACwEsA7MAOgBsB7ZCSqAYjADcwKsB9Bpi14AzAE70AtrwCuo2gG0ADAF1EoVPVhksZehTUgAHogC0AFgCMF6gA4AnAFYAbE7MAmCw8U2A7HYA0IACepk4O1ADMUREWbnY2Nm42sQ4AvqmBaJg4eISklDQCzBRsnNx8RUJikvz0RMi0ImS0YEqqSCAaWjp6BsYIJk42itQeEWaKFn5mZlFOgSEDYZHRsfGJyW5pGSBZ2LgExORU1Ki0yEG8HGRgAO7sXDy8qMjSsK0qBl3auvod-SYoiM7CCbE43E4fN54jMFogXGZqC5IRE3D4Et4HHZ0pl0PtckcCqdzpdrncHuVeLAsPRUG0vpofr1-ogLBFwltFJM7IofOMwVE4QhPIjkViHGYHPYbLMbDjdnicod8icXm8wLwAEZgYT0UQaqiGPhk+5lJ6kZAlDUm+kdb49P6gAFuCZIhHxFwWRSWCFChyot0uZxmOyWCZOeV7JV5Y40NXvLU6vUGsBGq43U2PPjU2m29SMh19RARPyB3yTKYWXwOIVhHzULlciKKBxTBzoiyRxUHGNEi0lIQmilPM4XdN3POdAu-IsIOzWaIxOIJJIpIVRRGN73tnxOCKQtxd7I9wknftQQcZ4fZml0z526fMp2IVdI3zotxJHxY3fzYKIf03EDMJXFDCxwyPfFlVjagyAgFpr2eElx1uSd7RnFkED3FZxjCdsnErashUSYDyysHwq2-SDo1PQpGGKKARHEKRZFoRDKhKJoKDIWASDQx9HSMUwJmsEEzHBLx5wcBw0QiIUpgiYDgzA8NDx2KMTxVOjBE46oWLkdj6KqSgeJISBeAoeheEtMgJFwX5+O6DDn2FDxqClfcZJDPcS3sIUTAhah50hNs4jiIYqPU7sCS0ugjN05janqRphGaMBDJ0xjUu43jHKZQSAVcesfBmMIplDWZ93kvklNAsNFHBdIdksiA4AMDSYtjBknKfISBh8JJRjZETpkqv9FgCgMbGiErFDccYS22XFj06okOKgbr8tncxpMDLwIh5CT-PA8JF1m+bZki5aoN7VVkJNTbC0wkZohcA6JncOwIprf8EA8EYQxBJxQ0SdwqzlKKVugol4w1bVdX1CzU2NDNHucvqAusMVFAOhqtlrGUcIWrwpSxMxqM0mDz0vO40d6gELDsagrFDVxfHcSF2xsP0A2RECQ3qiNIZu2jYPgsA6YK4tpobJsXH+uxFvkxwgpBGEStiL1Qwp1aTnWpialYyXtv3etIWBhrSeBuYhS+6hAa+kGXQ8BIdehvX4qyxKGGSpoWmNzDBhq+xnGmPl31tpx7bV4GzFBl2IfSIA */
    id: 'appStateMachine',
    initial: 'loading',
    tsTypes: {} as import("./viewerStateMachine.typegen").Typegen0,
    schema: {
        events: {} as TMachineStateEvent
    },
    states:{
        'loading':{
            on:{
                event_loading_from_url: {
                    target: "loading_from_url",
                },

                event_loading_from_local_file: {
                    target: "loading_from_local_file"
                }
            }
        },

        'play_view':{
            on:{
                'event_pause':{
                    target: 'pause_before_next_view',
                },
                'event_stop':{
                    target: 'idle',
                },
            }
        },

        'pause_before_next_view':{
            on:{
                'event_change_view':{
                    target: 'changing_view',
                },
                'event_stop':{
                    target: 'idle',
                },
            }
        },

        'changing_view':{
            on:{
                'event_play_view':{
                    target: 'play_view',
                },
                'event_stop':{
                    target: 'idle',
                },
            }

        },

        'idle':{
            on:{
                'event_play_view':{
                    target: 'play_view',
                }
            }
        },

        'loading_from_url': {
            on: {
                event_loading_finish: {
                    target:"idle",
                },
                event_loading_finished_no_animation: {
                    target: "idle",
                }
            }
        },

        'loading_from_local_file': {
            on: {
                event_loading_finish: "idle"
            }
        }
    },
})


export type TViewerState = State<unknown, TMachineStateEvent, any, { value: string, context: string }>


export const  useViewerState = () => {

    const [state, send] = useMachine(veiwerStateMachine)
    
    const sendViewerEvent = useCallback(
        (eventId: SCXML.Event<TMachineStateEvent> | SingleOrArray<Event<TMachineStateEvent>>) => { send(eventId) }
    ,[send])

    return({
        viewerState: state,
        sendViewerEvent 
    })
}


export default veiwerStateMachine
