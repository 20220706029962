import JSZip from 'jszip'
import { Dispatch, SetStateAction, } from 'react'
import { TTeethStepsPosition } from './ModelLoader'
import { TSteppedModelData, TTeethModelData } from '../types'
import JSZipUtils from '../Utils/jszip-utils'
import unzipSmilewrapper from './unzipSmilewrapper'
import detectZipFormat from './detectZipFormat'
import unzipObj from './unzipObj'

export type TSmilewrapperModelDataHandlers = {
    url: string
    setTeethModelData               : Dispatch< SetStateAction< TTeethModelData        >>
    setGingivaModelData             : Dispatch< SetStateAction< TSteppedModelData      >>
    setTeethModelStepTransformation : Dispatch< SetStateAction< TTeethStepsPosition[][]>>
    setSmilewrapperInfo             : Dispatch< SetStateAction< string | undefined     >>
    onGlobalError                   : (errorString:string) => void
    onFinish?                       : () => void 
}


const  getSmilewrapperModelsData = (handlers: TSmilewrapperModelDataHandlers) =>{
    const { url, onGlobalError } = handlers

    JSZipUtils.getBinaryContent(url, (err: any, data:ArrayBuffer) => {
                
        if(err) {
            console.log('CANT GET ZIP FILE')
            onGlobalError('CANT GET ZIP FILE')
            throw err; // or handle err
        }

        // UN-ZUP
        JSZip.loadAsync(data)
        .then((openedArchive:JSZip) => {
            
            const zipFormat = detectZipFormat(openedArchive)
            
            switch(zipFormat){

                case 'obj': {
                    unzipObj(openedArchive, handlers)
                } break
                
                case 'smilewrapper/ctm' : {
                    unzipSmilewrapper(openedArchive, handlers)
                } break

                default:{
                    onGlobalError("Zip file have unknown format")
                    console.error("Zip file have unknown format")
                } break
            }

        })
        
    })

}

export default getSmilewrapperModelsData
