import { useEffect, Dispatch, SetStateAction, useRef } from "react"
import * as THREE from 'three'
import { TSteppedModelData, TTeethModelData } from '../types'
import getSmilewrapperModelsData from './getSmilewrapperModelsData'
import getObjModelsData          from './getObjModelsData'
import getMultiZipObjModelsData from "./getMultiZipObjModelData"
import { Event, SCXML, SingleOrArray } from "xstate"
import { TMachineStateEvent } from "../Fsm/viewerStateMachine"
import getAnimationFlagFromUrl from "../Utils/getAnimationFlagFromUrl"
import axios from "axios"
import JSZip from "jszip"
import detectZipFormat from "./detectZipFormat"
import unzipObj from "./unzipObj"
import unzipSmilewrapper from "./unzipSmilewrapper"

type TModelLoaderProps = {
    url: string[]
    urlCash: React.MutableRefObject<string>
    setCasesUrlArray                : Dispatch< SetStateAction< string[]               >>
    setTeethModelData               : Dispatch< SetStateAction< TTeethModelData        >>
    setGingivaModelData             : Dispatch< SetStateAction< TSteppedModelData      >>
    setTeethModelGeometry           : Dispatch< SetStateAction< TSteppedModelData      >>
    setTeethModelStepTransformation : Dispatch< SetStateAction< TTeethStepsPosition[][]>>
    setSmilewrapperInfo             : Dispatch< SetStateAction< string | undefined     >>
    onGlobalError                   : (errorString:string) => void
    sendViewerEvent                 : (eventId: SCXML.Event<TMachineStateEvent> | SingleOrArray<Event<TMachineStateEvent>>) => void
}

export type TTeethStepsPosition = {
    id: string
    position: THREE.Vector3
    rotationMatrix: THREE.Matrix3
}


const ModelLoader = (props: TModelLoaderProps) => {
    const {
        url,
        urlCash,
        setCasesUrlArray,
        setTeethModelData,
        setGingivaModelData,
        setTeethModelStepTransformation,
        setTeethModelGeometry,
        setSmilewrapperInfo,
        onGlobalError,
        sendViewerEvent,
    } = props

    useEffect(()=>{
        if(!urlCash.current){
            if( url.length === 1 ){
                // ZIP with smilewrapper format inside
                if(url[0].indexOf('.zip')>-1){
                    urlCash.current = url[0]
                    getSmilewrapperModelsData({
                        url:url[0],
                        setTeethModelData,
                        setGingivaModelData,
                        setTeethModelStepTransformation,
                        setSmilewrapperInfo,
                        onGlobalError,
                        onFinish:()=>{
                            if(getAnimationFlagFromUrl() === false){
                                sendViewerEvent({type:'event_loading_finished_no_animation'})
                            }else{
                                setTimeout(()=>{
                                    sendViewerEvent('event_loading_finish')
                                },500)
                            }
                        }
                    })
                    
                } else 
                // CASE WITH JSON AND OBJ-FILES BESIDE
                if(url[0].indexOf('.json')>-1){
                    
                    getObjModelsData({
                        url:url[0],
                        setGingivaModelData,
                        setTeethModelGeometry,
                        onFinish:()=>{
                            setTimeout(()=>{
                                sendViewerEvent('event_loading_finish')
                            },500)
                        }
                    })
                } else {

                    axios.get(url[0],{
                        responseType: 'blob'
                    })
                    .then(response=>{
                        JSZip.loadAsync(response.data,{
                            optimizedBinaryString: true
                        })
                        .then((openedArchive:JSZip) => {
                            const zipFormat = detectZipFormat(openedArchive)
            
                            const handlers={
                                url:'',
                                setTeethModelData,
                                setGingivaModelData,
                                setTeethModelStepTransformation,
                                setSmilewrapperInfo,
                                onGlobalError,
                                onFinish:()=>{
                                    if(getAnimationFlagFromUrl() === false){
                                        sendViewerEvent({type:'event_loading_finished_no_animation'})
                                    }else{
                                        setTimeout(()=>{
                                            sendViewerEvent('event_loading_finish')
                                        },500)
                                    }
                                }
                            }

                            switch(zipFormat){

                                case 'obj': 
                                    unzipObj(openedArchive, handlers)
                                    break
                                
                                case 'smilewrapper/ctm' : 
                                    unzipSmilewrapper(openedArchive, handlers)
                                    break

                                default:
                                    onGlobalError("Zip file have unknown format")
                                    console.error("Zip file have unknown format")
                                    break
                            }
                        })
                    })
                    .catch((error)=>{
                        console.log(error)
                        onGlobalError('CANT LOAD ASSET AFTER REDIRECT')
                    })
                }
                
            } else if(url.length > 1){

                getMultiZipObjModelsData({
                    urlArray:url,
                    setGingivaModelData,
                    setTeethModelGeometry,
                    onGlobalError,
                    onFinish: ()=>{
                        if(getAnimationFlagFromUrl() === false){
                            sendViewerEvent({type:'event_loading_finished_no_animation'})
                        }else{
                            setTimeout(()=>{
                                sendViewerEvent('event_loading_finish')
                            },500)
                        }
                    }
                })
            }
        }
        
    // eslint-disable-next-line
    },[url])

    return(
        <>
        </>
    )
}

export default ModelLoader
