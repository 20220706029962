import packageInfo from '../../package.json'

type TInfoProps = {
    smilewrapperInfo?: string
    className?: string
}

const Information = (props:TInfoProps) =>{

    const { smilewrapperInfo, className } = props

    let splittedInfo = String(smilewrapperInfo).split('-') // UUID
    
    let localCaseId = smilewrapperInfo ? ( 
            splittedInfo.length === 5 || splittedInfo.length === 4 ?  // for version uuid.v4 or uuid.v1
                splittedInfo[splittedInfo.length-1] 
            : 
                String(smilewrapperInfo) 
        ) 
    : 
        '-'


    return(
        <div className={className ? className : 'information'}
            style={{
                bottom: localCaseId === '-' ? '18px' : '36px'  
            }}
        >
            <div className='info-wrapper'
            >
                <div className='info-line'>
                    <div className='value'>
                        { packageInfo.version.replace('-', '.') } js
                    </div>
                </div>
                {
                    localCaseId === '-' ?
                    null :
                        <div className='info-line'>
                            <div className='value'>
                                { localCaseId }
                            </div>
                        </div>
                    
                }
            </div>
        </div>
    )
}

export default Information
