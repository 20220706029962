import ReactDOM from 'react-dom/client'
import './index.sass'
import TpviewerSwitcher from "./TpviewerSwitcher"

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <TpviewerSwitcher/>
)

