import JSZip from "jszip"
import * as THREE from "three"
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader"
import { TSteppedModelData, TTeethModelData } from "../types"
import { mergeBufferGeometries } from "./BufferGeometryUtils"
import { TSmilewrapperModelDataHandlers } from "./getSmilewrapperModelsData"

const unzipObj = (openedArchive:JSZip, handlers: TSmilewrapperModelDataHandlers, onFinish?: () => void) =>{
    const {
        setTeethModelData,
        setGingivaModelData,
        onGlobalError
    } = handlers

    const files = Object
    .entries(openedArchive.files)
    .map(item => item[1])
    .sort((a,b)=>a.name.localeCompare(b.name))
    
    const teethModelData:TTeethModelData = {
        upper: [],
        lower: []
    }

    const gingivasModelData:TSteppedModelData = {
        upperSteps: [],
        lowerSteps: []
    }

    const objFilesHandlers = files.map((fileItem, index)=>{
        return(
            fileItem.async('text')
            .then( function(unzippedData){
                const parsedObj:THREE.Group = new OBJLoader().parse( unzippedData )
    
                // COLLECT TEETH 3D-OBJECTS
                const upperTeeths:THREE.BufferGeometry[] = []
                const lowerTeeths:THREE.BufferGeometry[] = []
                
                let gingivaUpper:THREE.BufferGeometry | undefined 
                let gingivaLower:THREE.BufferGeometry | undefined 

                parsedObj.traverse((objItem:any)=>{
                    if(objItem.geometry){
                        
                        objItem.geometry.name = objItem.name

                        if((objItem.name.indexOf('teeth')>-1 ||
                            objItem.name.indexOf('Tooth')>-1
                        )&&(
                            objItem.name.indexOf('_1')>-1 ||
                            objItem.name.indexOf('_2')>-1 ||
                            objItem.name.indexOf(' 1')>-1 ||
                            objItem.name.indexOf(' 2')>-1     
                        )){
                            upperTeeths.push(objItem.geometry)
                        }

                        if((objItem.name.indexOf('teeth')>-1 ||
                            objItem.name.indexOf('Tooth')>-1
                        )&&(
                            objItem.name.indexOf('_3')>-1 ||
                            objItem.name.indexOf('_4')>-1 ||
                            objItem.name.indexOf(' 3')>-1 ||
                            objItem.name.indexOf(' 4')>-1     
                        )){
                            
                            lowerTeeths.push(objItem.geometry)
                        }

                        if(objItem.name.indexOf('Mandible')>-1){
                            gingivaLower = objItem.geometry
                        }

                        if(objItem.name.indexOf('Maxilla')>-1){
                            gingivaUpper = objItem.geometry
                        }
                    }
                })

                const mergedUpperTeethsGeometry = mergeBufferGeometries(upperTeeths)
                const mergedLowerTeethsGeometry = mergeBufferGeometries(lowerTeeths)

                teethModelData.upper.push({
                    name: 'Teeths_upper',
                    data: mergedUpperTeethsGeometry
                })

                teethModelData.lower.push({
                    name: 'Teeths_lower',
                    data: mergedLowerTeethsGeometry
                })

                gingivasModelData.lowerSteps.push({
                    name: 'Gingiva_lower',
                    data: gingivaLower ? gingivaLower : new THREE.BufferGeometry()
                })

                gingivasModelData.upperSteps.push({
                    name: 'Gingiva_upper',
                    data: gingivaUpper ? gingivaUpper : new THREE.BufferGeometry()
                })
            })
            .catch((error)=>{
                onGlobalError("Can't unzip OBJ files")
                console.log("Can't unzip this OBJ file", fileItem)
            })
        )
    })

    Promise.all([...objFilesHandlers]).then(()=>{
        setTeethModelData(teethModelData)
        setGingivaModelData(gingivasModelData)

        if(
            (
                gingivasModelData.lowerSteps.length === 0 ||
                gingivasModelData.upperSteps.length === 0 
            ) ||
            (
                teethModelData.lower.length === 0 ||
                teethModelData.upper.length === 0
            )
        ){
            onGlobalError('ZIP-file content is empty')
        }
        if( onFinish ){
            onFinish()
        }

    }).catch((error)=>{
        onGlobalError("Can't unzip OBJ files")
        console.log("Can't unzip OBJ files", JSON.stringify(error))
    })

}

export default unzipObj
