import { MutableRefObject, useEffect, useRef } from "react"
import { TApplicationActiveTab, TViewResetFlag } from "../JsTpviewer"
import { TViewValue } from "../types"
import { useFrame } from "@react-three/fiber"
import getZoomValue from "../Utils/get-viewport-zoom-value"

type TCameraZoomControllerProps = {
    activeTab?: TApplicationActiveTab
    currentView: TViewValue
    zoomWheelValue: MutableRefObject<number>
    isNeedToResetViewRef: React.MutableRefObject<TViewResetFlag> 
    viewType: 'main' |  'before' | 'after' 
}


const CameraZoomController = (props:TCameraZoomControllerProps) =>{
    
    const { 
        activeTab,
        currentView,
        zoomWheelValue,
        isNeedToResetViewRef,
        viewType
    } = props
    
    const isNeedUpdate = useRef(true)

    useEffect(()=>{
        isNeedUpdate.current = true
    },[currentView])


    useFrame(({ gl, scene, camera }) => {
        if(isNeedUpdate.current === true){

            isNeedUpdate.current = false

            camera.zoom = getZoomValue(activeTab)
            camera.updateProjectionMatrix()
            gl.render(scene, camera)
        }

        if(zoomWheelValue.current!==0){
            
                if(zoomWheelValue.current === 1){
                    camera.zoom = camera.zoom + 0.5
                }else if(zoomWheelValue.current === -1){
                    if(camera.zoom > 1){
                        camera.zoom = camera.zoom - 0.5
                    }
                }
                camera.updateProjectionMatrix()
            
        }
        
    })

    return(<></>)
}

export default CameraZoomController
