import JSZip from "jszip"

export type TZipFileFormat = 'smilewrapper/ctm' | 'obj' | 'unknown'

const detectZipFormat = (zipFile:JSZip):TZipFileFormat =>{
    const fileKeyArray = Object.keys(zipFile.files)
    
    for(let i = 0; i<fileKeyArray.length; i++){
        if(fileKeyArray[i].indexOf('smilewrapper.json')>-1){
            return('smilewrapper/ctm')
        }
    }

    let objCounter:number = 0
    for(let i = 0; i<fileKeyArray.length; i++){
        if(fileKeyArray[i].indexOf('.obj')>-1){
            objCounter++
        }
    }
    if(objCounter === fileKeyArray.length){
        return('obj')
    }

    return('unknown')
}

export default detectZipFormat
